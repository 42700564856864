import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import { StaticImage } from 'gatsby-plugin-image'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import ColorButton from '../components/Ui/ColorButton'

const DeveloppementDurable = () => {
  const metaTitle = 'Développement durable | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Présentation des bilans annuels du Festif! en matière de développement durable et d’empreinte écologique.'
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, développement durable, environnement, écologique'

  return (
    <Wolflayout mobileTitle={'Dev Durable'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <Banner
        title="Développement"
        titleLine2="durable"
      />

      <div className="container content dev-durable pb-12">

        {/* YOUTUBE */}
        <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
          <div className="mt-10 relative md:h-[35rem] h-[12rem] md:px-[60px] px-[10px]">
            <iframe
              title="Notre petite histoire du développement durable"
              className="absolute top-0 left-0 w-full h-full mb-3rem"
              src={'https://www.youtube.com/embed/XZknssKQia4'}
              allowFullScreen=""
              referrerPolicy="origin-when-cross-origin"
              height={'100%'}
            ></iframe>
          </div>
        </div>

        {/* CURRENT POLICY */}
        <section className="flex flex-wrap py-12 md:px-[60px] px-[10px]">
          <a className="w-full lg:w-7/12 bg-yellow-wolf2025 rounded-3xl text-center flex items-center !no-underline md:p-36 lg:py-18 py-12 mb-4 md:mb-0 hover:scale-105 transition duration-300"
             href="https://prismic-io.s3.amazonaws.com/le-festif-ca/b2c936fd-6459-43f4-84cf-092f282e0f08_Festif_Politique-DD-2023_final.pdf">
            <div className="flex flex-col items-center">
              <h3 className="leading-6">LA POLITIQUE DE DÉVELOPPEMENT DURABLE</h3>
              <h5 className="leading-none hover:underline">Consulter →</h5>
            </div>
          </a>
          <div className="w-full lg:w-5/12 md:pl-12 pl-0">
            <p className="!p-0">Pour en connaître davantage sur nos actions écoresponsables, consulte les bilans des éditions précédentes ainsi que notre toute nouvelle politique de développement durable.</p>
            <br/>
            <p className="!p-0">L'équipe demeure en constant apprentissage et est ouverte à recevoir quelconque suggestion ou commentaire constructif par rapport à ce document.</p>
            <br/>
            <p className="!p-0"><b>Merci de prendre part au mouvement !</b></p>
            <StaticImage className="mt-6 max-h-[50px]" imgClassName="!object-contain object-left" src="../images/partenaires/recyc-quebec-logo.png" alt="Logo Recyc-Québec" placeholder="none"/>
          </div>
        </section>

        {/* BILANS */}
        <section>
          <h2>Consulte nos bilans développement durable</h2>
          <div className="flex flex-wrap md:px-[60px] px-[10px]">
            <div className="w-full lg:w-1/3 text-center flex items-center md:pr-2 pr-0 text-purple-wolf2025 hover:scale-105 transition duration-300">
              <a className="bg-yellow-wolf2025 rounded-3xl !no-underline p-4 mb-4 md:mb-0"
                 href="https://prismic-io.s3.amazonaws.com/le-festif-ca/dbab826d-c6d9-4d6e-9364-a9f3e72afdf5_++++lefestif_bilan_dd_2023.pdf">
                <div className="flex flex-col items-center">
                  <h3 className="leading-6">2023 - BILAN DÉVELOPPEMENT DURABLE</h3>
                  <h5 className="leading-none hover:underline">Consulter →</h5>
                </div>
              </a>
            </div>
            <div className="w-full lg:w-1/3 text-center flex items-center md:px-4 px-0 hover:scale-105 transition duration-300">
              <a className="bg-blue-wolf2025 rounded-3xl !no-underline p-4 mb-4 md:mb-0"
                 href="https://prismic-io.s3.amazonaws.com/le-festif-ca/d2214115-98e1-4df9-a1d2-cb97cc326157_lefestif_bilan_dd_2022.pdf">
                <div className="flex flex-col items-center">
                  <h3 className="leading-6">2022 - BILAN DÉVELOPPEMENT DURABLE</h3>
                  <h5 className="leading-none hover:underline">Consulter →</h5>
                </div>
              </a>
            </div>
            <div className="w-full lg:w-1/3 text-center flex items-center md:px-4 px-0 hover:scale-105 transition duration-300">
              <a className="bg-pink-wolf2025 rounded-3xl !no-underline p-4 mb-4 md:mb-0"
                 href="https://prismic-io.s3.amazonaws.com/le-festif-ca/83ed8158-4d12-46ef-867d-04c2225b7b42_lefestif_bilan_dd_2019.pdf">
                <div className="flex flex-col items-center">
                  <h3 className="leading-6">2019 - BILAN DÉVELOPPEMENT DURABLE</h3>
                  <h5 className="leading-none hover:underline">Consulter →</h5>
                </div>
              </a>
            </div>
          </div>
          <div className="flex flex-wrap md:px-[60px] px-[10px] md:mt-8 mt-0">
            <div className="w-full lg:w-1/3 text-center flex items-center md:pl-2 pl-0 hover:scale-105 transition duration-300">
              <a className="bg-blue-wolf2025 rounded-3xl !no-underline p-4 mb-4 md:mb-0"
                 href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ee232775-fdd0-462d-a61b-e76afe3e39d9_lefestif_bilan_dd_2018.pdf">
                <div className="flex flex-col items-center">
                  <h3 className="leading-6">2018 - BILAN DÉVELOPPEMENT DURABLE</h3>
                  <h5 className="leading-none hover:underline">Consulter →</h5>
                </div>
              </a>
            </div>
            <div className="w-full lg:w-1/3 text-center flex items-center md:px-4 px-0 hover:scale-105 transition duration-300">
              <a className="bg-pink-wolf2025 rounded-3xl !no-underline p-4 mb-4 md:mb-0"
                 href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/9b7a4940-d11f-4a28-9dbc-5b771d731030_lefestif_bilan_dd_2017.pdf">
                <div className="flex flex-col items-center">
                  <h3 className="leading-6">2017 - BILAN DÉVELOPPEMENT DURABLE</h3>
                  <h5 className="leading-none hover:underline">Consulter →</h5>
                </div>
              </a>
            </div>
            <div className="w-full lg:w-1/3 text-center flex items-center md:px-4 px-0 hover:scale-105 transition duration-300">
              <a className="bg-yellow-wolf2025 rounded-3xl !no-underline p-4 mb-4 md:mb-0"
                 href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/2065ec53-ce1a-4e7d-814c-fd5bbc116abe_lefestif_bilan_dd_2016.pdf">
                <div className="flex flex-col items-center">
                  <h3 className="leading-6">2016 - BILAN DÉVELOPPEMENT DURABLE</h3>
                  <h5 className="leading-none hover:underline">Consulter →</h5>
                </div>
              </a>
            </div>
          </div>
        </section>

        {/* ACTIONS */}
        <section>
          <h2>Quelques-unes de nos actions</h2>

          <h4>Politique de sobriété numérique</h4>
          <p>Considérant la place importante que prend le numérique dans le quotidien de nos ressources et dans les communications de l’organisme, Le Festif! considère primordial d’amorcer une transition vers une vie numérique plus sobre.</p>
          <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
            <p><ColorButton href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZoVl3B5LeNNTwwsg_Sobrie%CC%81te%CC%81-nume%CC%81rique_V2.pdf" text="Consulter la politique" externalLink="true" className="mt-6 mb-9"/></p>
          </div>
          <h4>Un festival zéro déchet (Bye bye plastique !)</h4>
          <p>Nous sommes fier·ères de pouvoir dire que nous ne vendons aucun produit jetable et que, grâce à nos verres et à nos bouteilles réutilisables, à la vaisselle et aux contenants biodégradables, ainsi qu’à un système de recyclage et de récupération exemplaire, nous ne produisons aucun déchet ultime pendant le festival.</p>

          <h4>Loges d’artistes en vrac</h4>
          <p>Le Festif! adhère au mouvement <a href="http://act-tour.org/" target="_blank" rel="noreferrer">ACT</a>, c’est la raison pour laquelle les produits offerts dans les loges de nos artistes le sont en vrac, ne produisant donc au passage aucun déchet !</p>

          <h4>Stations d’eau potable - RECYC-QUÉBEC</h4>
          <p>Au Festif!, il fait soleil (on se croise les doigts) ! On prend donc soin de ton hydratation en t'offrant des stations d’approvisionnement en eau potable, grâce auxquelles tu pourras remplir à ta guise tes verres et bouteilles réutilisables.</p>
          <p><StaticImage className="mb-2 mt-6 max-h-[32px]" imgClassName="!object-contain object-left" src="../images/partenaires/recyc-quebec-logo.png" alt="Logo Recyc-Québec" placeholder="none"/></p>

          <h4>Récupération de mégots</h4>
          <p>Tu veux t'en griller une? Aucun jugement. Toutefois, garde l’œil ouvert et dépose tes mégots dans l’un des nombreux cendriers installés aux abords de nos différents sites. En collaboration avec l’entreprise TerraCycle, ces déchets seront par la suite récupérés et transformés en différents objets quotidiens pratiques !</p>

          <h4>Boire local, manger local</h4>
          <p>Tous les produits alimentaires servis sur les sites de spectacle sont produits et transformés à 100 km ou moins de Baie-Saint-Paul. Une occasion en or de découvrir nos formidables producteur·ices locaux!</p>

          <h4>Festival piéton</h4>
          <p>Les sites de spectacles étant tous situés à de courtes distances les uns des autres, oublie ta voiture le temps d’un week-end et découvre la ville à pied ou à vélo (location offerte sur place par notre partenaire Baie-Cycle) !</p>
          <p><ColorButton href="/infos/transports#info_content" text="Transport" className="mt-6 mb-9" /></p>

          <h4>Réduction des imprimés</h4>
          <p>Depuis quelques années, Le Festif! s’efforce à bannir la quantité de dépliants, de prospectus, d’affiches et de billets imprimés. Télécharge notre <a href="/infos/appmobile#info_content">application mobile</a> pour obtenir en ligne toutes les informations dont tu as besoin !</p>
        </section>

          {/* KIOSQUE */}
          <section>
            <h2>Kiosque Développement Durable RECYC-QUÉBEC</h2>
            <p>Viens en découvrir plus à propos de nos actions éco-responsables en visitant cet espace d’information : conférences, sondages, sensibilisation, consultation des bilans des dernières éditions, discussions... Les festivalier·ères seront aussi invité·es à laisser leurs suggestions et idées d'actions vertes pour les futures éditions de l'événement.</p>
            <p>Rendez-vous sur la page <a href="/infos/ateliersetconferences#info_content">Ateliers et conférences</a> pour connaître la programmation.</p>
          </section>

          {/* PROMESSE */}
          <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
            <section>
              <h2>La promesse des Festivalier·ères</h2>
              <p><ColorButton href="https://forms.gle/jewnQgDyrdeRhApeA" text="SIGNER LA PROMESSE" externalLink="true" className="my-4" /></p>
            </section>
          </div>

          {/* LQ BANNER */}
          <div className="w-full center py- mt-24">
            <div className="max-w-[550px] max-h-[200px] mx-auto">
              <a className="" href="https://www.recyc-quebec.gouv.qc.ca/appmobile-cavaou/">
                <StaticImage src="../images/partenaires/recycquebec-banner.jpg"/>
              </a>
            </div>
          </div>

      </div>

    </Wolflayout>
  )
}

export default DeveloppementDurable
